import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]

  connect () {
    addEventListener("click", this.onWindowClick.bind(this), true)
  }

  disconnect () {
    removeEventListener("click", this.onWindowClick.bind(this), true)
  }

  // Hide menu on click outside
  onWindowClick (event) {
    if (!event.composedPath().includes(this.element))
      this.setState(true)
  }

  toggle (event) {
    event.preventDefault()
    this.setState()
  }

  /**
   * Set new hidden state
   * @param {boolean?} state Hide or show menu
   */
  setState (state) {
    const { menu } = this

    // Default to toggle
    state ??= !menu.hidden

    const transition = this.application.getControllerForElementAndIdentifier(menu, "transition")
    if (transition) {
      state ? transition.leave() : transition.enter()
    } else {
      menu.hidden = state
    }
  }

  close () {
    this.menu.hidden = true
  }

  /** @type {HTMLElement} */
  get menu () {
    return this.menuTarget
  }
}
