import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["value"]
  static values = { x: String, y: String }

  connect () {
    addEventListener("resize", this.updateDotPositionDebounced.bind(this))
    this.mediaElement.addEventListener("click", this.setValue.bind(this))
    this.mediaElement.addEventListener("load", this.updateDotPosition.bind(this), { once: true })
    this.mediaElement.addEventListener("loadeddata", this.updateDotPosition.bind(this), { once: true })

    if (this.mediaElement.complete || this.mediaElement.readyState === 4)
      this.updateDotPosition()
  }

  disconnect () {
    removeEventListener("resize", this.updateDotPositionDebounced.bind(this))
    this.mediaElement.removeEventListener("click", this.setValue.bind(this))
    this.mediaElement.removeEventListener("load", this.setValue.bind(this))
    this.mediaElement.removeEventListener("loadeddata", this.setValue.bind(this))
  }

  setValue (event) {
    event.preventDefault()

    const round = (value) => Math.round(value * 1000) / 1000
    const x = round(event.offsetX / this.mediaElement.clientWidth)
    const y = round(event.offsetY / this.mediaElement.clientHeight)

    this.inputs.x.value = x
    this.inputs.y.value = y

    this.updateDotPosition()
  }

  updateDotPosition () {
    const { x, y } = this.inputs

    this.dot.hidden = false
    this.dot.style.transform = `translate(${x.value * this.mediaElement.clientWidth}px, ${y.value * this.mediaElement.clientHeight}px)`
  }

  updateDotPositionDebounced () {
    if (this.updateDotPositionDebounced.timer)
      clearTimeout(this.updateDotPositionDebounced.timer)

    this.updateDotPositionDebounced.timer = setTimeout(() => {
      this.updateDotPosition()
      this.updateDotPositionDebounced.timer = null
    }, 100)
  }

  get mediaElement () {
    /** @type {HTMLImageElement | HTMLVideoElement} */
    return this.element.querySelector("video") || this.element.querySelector("img")
  }

  get inputs () {
    return {
      /** @type {HTMLElement} */
      x: this.element.querySelectorAll(`input[type=hidden]`)[0],
      /** @type {HTMLElement} */
      y: this.element.querySelectorAll(`input[type=hidden]`)[1],
    }
  }

  /** @type {HTMLElement} */
  get dot () {
    return this.valueTarget
  }
}